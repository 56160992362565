import React from "react";

import Layout from "components/Layout";
import { SimplePageTemplate } from "templates/simplePage";

/**
 * @typedef {object} Props
 * @prop {Location} location
 */
export default function NotFound() {
  return (
    <Layout>
      <SimplePageTemplate title="Page Not Found" body={null} />
    </Layout>
  );
}
